<script>
import { layoutMethods } from '@/state/helpers'
import Swal from 'sweetalert2'
import service from '@/server/http_service'
/**
 * Right-sidebar
 */
export default {
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ['click'],
      },
      layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
      width: this.$store ? this.$store.state.layout.layoutWidth : {} || {},
      topbar: this.$store ? this.$store.state.layout.topbar : {} || {},
      sidebarType: this.$store ? this.$store.state.layout.leftSidebarType : {} || {},
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
      account_data: localStorage.getItem('users') ? JSON.parse(localStorage.getItem('users')) : [],
    }
  },
  mounted() {
    // console.log(this.account_data)
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar()
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar()
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList) return !event.target.classList.contains('toggle-right')
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      })
    },
    changeType(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      })
    },
    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      })
    },
    changeTopbartype(value) {
      return this.changeTopbar({
        topbar: value,
      })
    },
    changeloader() {
      return this.changeLoaderValue({
        loader: this.loader,
      })
    },
    routersClick(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
      // console.log(this.$route.query.page)
    },
    logoutFunc() {
      this.hide()
      Swal.fire({
        title: 'คุณต้องการออกจากระบบ ใช่ หรือ ไม่',
        text: '',
        icon: 'warning',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่',
        allowOutsideClick: true,
        showCancelButton: true,
        showCloseButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          // localStorage.clear()
          // window.location.reload()
          window.location.href = '/logout'
        } else if (result.isDenied) {
          return
        }
      })
    },
    checkAuthPlatform() {
      try {
        service
          .postService('checkAuthPlatform', {
            account_data: this.account_data[0],
            server_name: location.host,
          })
          .then(rp => {
            // console.log(rp)
            if (rp.code == '200') {
              location.replace(rp.data.token)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div>
          <b-row>
            <b-col>
              <div class="side-nav-menu-title">
                <h5 class="mb-0 font-color-blue">{{ $t('right_sidebar.goods.title') }}</h5>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/transportation_plan/create')">
                    <img src="@/assets/images/right_bar_menu/plus (1).svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.goods.create') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/transportation_plan/plans_display')">
                    <img src="@/assets/images/right_bar_menu/box.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.goods.plans') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/transportation_plan/search')">
                    <img src="@/assets/images/right_bar_menu/Path 5834.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.goods.search') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/transportation_plan/tracking')">
                    <img src="@/assets/images/right_bar_menu/Group 4988.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.goods.tracking') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/transportation_plan/history')">
                    <img src="@/assets/images/right_bar_menu/Group 9846.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.goods.history') }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="side-nav-menu-title">
                <h5 class="mb-0 font-color-blue">{{ $t('right_sidebar.carrier.title') }}</h5>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/carrier/create')">
                    <img src="@/assets/images/right_bar_menu/Group 4986.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.carrier.create') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/carrier/plans_display')">
                    <img src="@/assets/images/right_bar_menu/delivery-truck (1).svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.carrier.plans') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/carrier/search')">
                    <img src="@/assets/images/right_bar_menu/Path 5834.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.carrier.search') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/carrier/tracking')">
                    <img src="@/assets/images/right_bar_menu/Group 4985.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.carrier.tracking') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/carrier/history')">
                    <img src="@/assets/images/right_bar_menu/Group 9846.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.carrier.history') }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="side-nav-menu-title">
                <h5 class="mb-0 font-color-blue">{{ $t('right_sidebar.manage.title') }}</h5>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/profile/personal_profile')">
                    <img src="@/assets/images/right_bar_menu/user.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.manage.personal') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/profile/truck')">
                    <img src="@/assets/images/right_bar_menu/delivery-truck (1).svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.manage.truck') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="routersClick('/profile/settings')">
                    <!-- <img src="@/assets/images/right_bar_menu/box.svg" height="15" width="15" class="icon-side-nav-menu" /> -->
                    <i class="ri-settings-5-line align-middle icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.manage.settings') }}</span>
                  </b-col>
                </b-row>
              </div>
              <!-- <div class="side-nav-menu-dis">
                <b-row>
                  <b-col>
                    <img src="@/assets/images/right_bar_menu/box.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.manage.goods') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu-dis">
                <b-row>
                  <b-col>
                    <img src="@/assets/images/right_bar_menu/user.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.manage.employee') }}</span>
                  </b-col>
                </b-row>
              </div> -->
              <br />
              <div v-if="account_data[0].oauth_provider == 'dtc'" class="side-nav-menu">
                <b-row>
                  <b-col @click="checkAuthPlatform()">
                    <img src="@/assets/images/right_bar_menu/png/dtc_logo2.png" height="15" width="15" class="icon-side-nav-menu" />
                    <span>{{ $t('right_sidebar.dtc_platform') }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="side-nav-menu">
                <b-row>
                  <b-col @click="logoutFunc()">
                    <img src="@/assets/images/right_bar_menu/exit.svg" height="15" width="15" class="icon-side-nav-menu" />
                    <span style="color: #FF7272">{{ $t('right_sidebar.logout') }}</span>
                  </b-col>
                </b-row>
              </div>
              <br />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay" />
  </div>
</template>

<style>
.side-nav-menu-title {
  margin: 1.5rem 1.5rem 1rem 20px;
}
.side-nav-menu {
  padding: 15px 15px 15px 20px;
  cursor: pointer;
  color: #505d69;
}
.side-nav-menu-dis {
  padding: 15px 15px 15px 20px;
  cursor: default;
  color: #505d69;
}
.side-nav-menu:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
.icon-side-nav-menu {
  margin-right: 0.8rem;
}
</style>
